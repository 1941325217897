<template>
  <div class="payment">
    <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" />
    <CategoryTitle :category="category" class="ma-7" />
    <v-container fluid v-if="getProposals('header-banner').length > 1">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <v-container fluid class="pt-0">
      <v-row>
        <v-col cols="12" md="8" class="cart-info pa-0 pa-sm-3">
          <CartInfoList class="cart-info-list py-8" :isCart="true" />

          <!-- visualizzazione mobile -->
          <div class="summary-cards">
            <CheckoutSummary
              class="payment-summary d-flex d-md-none flex-column"
              :orderCart="cart"
            />
          </div>
          <!--  -->
          <v-card class="py-3 px-3 text-left checkout-summary" flat>
            <v-text-field
              v-if="cart.user.person.personTypeId == 4"
              v-model="codiceCigInput"
              ref="cigCodeRef"
              :rules="cigRules"
              label="Codice CIG *"
              dense
              depressed
              outlined
              @blur="updateCodiceCig"
            ></v-text-field>
          </v-card>

          <v-alert
            v-if="
              cart.user.person.personTypeId == 4 &&
                !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)
            "
            type="error"
            >{{ $t("paymentTypeList.disabledNoCig") }}</v-alert
          >

          <div
            v-if="
              !(
                cart.user.person.personTypeId == 4 &&
                !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)
              )
            "
          >
            <PaymentTypeList
              :options="{
                showRememberCard: false,
                showPaymentMethodImage: false,
                showPrebilledAmountHtml: true,
                showPrebilledAmountInfoText: true,
                orderAmount: cart.grossTotal,
                confirm: { color: 'secondary' },
                titleStyle: 'primary--text text-h4 mb-2'
              }"
              :isPaymentDisabled="
                cart.user.person.personTypeId == 4 &&
                  !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)
              "
            />
          </div>
          />
          <RecaptchaDisclaimer></RecaptchaDisclaimer>

          <!-- cart.user.person.personTypeId == 4 && !codiceCig -->
        </v-col>
        <!-- visualizzazione desktop -->
        <v-col
          cols="12"
          md="4"
          class="summary-cards d-none d-md-flex flex-column"
          v-if="cart.shippingAddress"
        >
          <CheckoutSummary
            class="payment-summary d-flex flex-column"
            :orderCart="cart"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="spacer-scroll"></v-spacer>
    <v-container v-if="getProposals('footer-banner').length > 1">
      <ProposalSlider :proposals="getProposals('footer-banner')" />
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CategoryTitle from "@/components/category/CategoryTitle";
import CheckoutSummary from "@/components/cart/CheckoutSummary";
import CartInfoList from "@/components/cart/CartInfoList";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider";

import CartService from "~/service/cartService";
import AnalyticsService from "~/service/analyticsService";

import login from "~/mixins/login";
import productMixins from "~/mixins/product";
import cartInfo from "~/mixins/cartInfo";
import { mapCartInfo } from "~/service/ebsn";
import {
  minLength,
  requiredValue,
  isAlphaNum,
  maxLength
} from "~/validator/validationRules";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Payment",
  data() {
    return {
      paymentTypeList: [],
      packagesList: [],
      codiceCigInput: "",
      cigRules: [
        requiredValue("Digitare il codice CIG"),
        isAlphaNum("Usare solo lettere e numeri"),
        minLength(10, "Il codice CIG deve essere lungo 10 caratteri"),
        maxLength(10, "Codice CIG troppo lungo")
      ]
    };
  },
  components: {
    PaymentTypeList,
    Breadcrumb,
    CategoryTitle,
    CheckoutSummary,
    CartInfoList,
    ProposalSlider,
    RecaptchaDisclaimer
  },
  created() {
    this.fetchPackages();

    AnalyticsService.checkoutProgress(this.cart);
    this.codiceCigInput = get(
      this.$store.state.cart.cart,
      "cartInfos.CODICE_CIG"
    );
    // this.getUserDetails();
    //this.codiceCigInput = "";
  },
  async mounted() {
    await this.updateCodiceCig();
  },
  mixins: [login, productMixins, cartInfo, categoryMixins],

  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Payment",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    }),
    ...mapCartInfo({
      codiceCig: "CODICE_CIG"
    })
  },
  methods: {
    goToTerms() {
      window.open("/page/condizioni-di-vendita/", "_blank");
    },
    updateCodiceCig() {
      if (this.$refs.cigCodeRef?.valid) {
        this.$store.dispatch("cart/setCartInfo", {
          CODICE_CIG: this.codiceCigInput
        });
      }
    },
    fetchPackages() {
      CartService.getPackages(this.warehouseId, false).then(packagesList => {
        if (packagesList) {
          this.packagesList = packagesList;

          this.packagesList = this.packagesList.concat([
            {
              available: 10,
              productId: 0,
              name: "Nessun sacchetto",
              shortDescr: "",
              description: "",
              mediaURL: "",
              highMediaURL: ""
            }
          ]);
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.cart && vm.cart.totalItems > 0) {
        next();
      } else {
        next("/");
      }
    });
  }
};
</script>
