var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment"},[_c('Breadcrumb',{staticClass:"hidden-sm-and-down px-0",attrs:{"items":_vm.breadcrumbs}}),_c('CategoryTitle',{staticClass:"ma-7",attrs:{"category":_vm.category}}),(_vm.getProposals('header-banner').length > 1)?_c('v-container',{attrs:{"fluid":""}},[_c('ProposalSlider',{attrs:{"proposals":_vm.getProposals('header-banner')}})],1):_vm._e(),_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"cart-info pa-0 pa-sm-3",attrs:{"cols":"12","md":"8"}},[_c('CartInfoList',{staticClass:"cart-info-list py-8",attrs:{"isCart":true}}),_c('div',{staticClass:"summary-cards"},[_c('CheckoutSummary',{staticClass:"payment-summary d-flex d-md-none flex-column",attrs:{"orderCart":_vm.cart}})],1),_c('v-card',{staticClass:"py-3 px-3 text-left checkout-summary",attrs:{"flat":""}},[(_vm.cart.user.person.personTypeId == 4)?_c('v-text-field',{ref:"cigCodeRef",attrs:{"rules":_vm.cigRules,"label":"Codice CIG *","dense":"","depressed":"","outlined":""},on:{"blur":_vm.updateCodiceCig},model:{value:(_vm.codiceCigInput),callback:function ($$v) {_vm.codiceCigInput=$$v},expression:"codiceCigInput"}}):_vm._e()],1),(
            _vm.cart.user.person.personTypeId == 4 &&
              !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)
          )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.$t("paymentTypeList.disabledNoCig")))]):_vm._e(),(
            !(
              _vm.cart.user.person.personTypeId == 4 &&
              !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)
            )
          )?_c('div',[_c('PaymentTypeList',{attrs:{"options":{
              showRememberCard: false,
              showPaymentMethodImage: false,
              showPrebilledAmountHtml: true,
              showPrebilledAmountInfoText: true,
              orderAmount: _vm.cart.grossTotal,
              confirm: { color: 'secondary' },
              titleStyle: 'primary--text text-h4 mb-2'
            },"isPaymentDisabled":_vm.cart.user.person.personTypeId == 4 &&
                !(this.$refs.cigCodeRef && this.$refs.cigCodeRef.valid)}})],1):_vm._e(),_vm._v(" /> "),_c('RecaptchaDisclaimer')],1),(_vm.cart.shippingAddress)?_c('v-col',{staticClass:"summary-cards d-none d-md-flex flex-column",attrs:{"cols":"12","md":"4"}},[_c('CheckoutSummary',{staticClass:"payment-summary d-flex flex-column",attrs:{"orderCart":_vm.cart}})],1):_vm._e()],1)],1),_c('v-spacer',{staticClass:"spacer-scroll"}),(_vm.getProposals('footer-banner').length > 1)?_c('v-container',[_c('ProposalSlider',{attrs:{"proposals":_vm.getProposals('footer-banner')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }